html,
body {
  font-family: 'Open Sans', sans-serif;
  color: #0d0d0d;
}

html {
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@keyframes loaderAnimation {
  0% {
    height: 0;
  }

  50% {
    height: 100%;
  }

  100% {
    height: 0;
  }
}

.icon-libertymutual {
  background-image: url(../public/images/carrier-logos/liberty-mutual.svg);
}
.icon-nationwidecarrier {
  background-image: url(../public/images/carrier-logos/nationwide.svg);
}
.icon-acuity {
  background-image: url(../public/images/carrier-logos/acuity.svg);
}
.icon-hiscox {
  background-image: url(../public/images/carrier-logos/hiscox.svg);
}
.icon-travelers {
  background-image: url(../public/images/carrier-logos/traverlers.svg);
}
.icon-biberk {
  background-image: url(../public/images/carrier-logos/biberk.svg);
}
.icon-coterie {
  background-image: url(../public/images/carrier-logos/coterie.svg);
}
.icon-cna {
  background-image: url(../public/images/carrier-logos/cna.svg);
}
.icon-stateauto {
  background-image: url(../public/images/carrier-logos/state-auto.svg);
}
.icon-nowcarrier {
  background-image: url(../public/images/carrier-logos/now-insurance.svg);
}
.icon-cowbellcarrier {
  background-image: url(../public/images/carrier-logos/cowbell-cyber.svg);
}
.icon-thimble {
  background-image: url(../public/images/carrier-logos/thimble.svg);
}
.icon-chubbcarrier {
  background-image: url(../public/images/carrier-logos/chubb-logo.svg);
}
.icon-amtrust {
  background-image: url(../public/images/carrier-logos/am-trust.svg);
}
.icon-employers {
  background-image: url(../public/images/carrier-logos/employers.svg);
}
.icon-nextcarrier {
  background-image: url(../public/images/carrier-logos/next.svg);
}
.icon-piecarrier {
  background-image: url(../public/images/carrier-logos/pie-insurance.svg);
}

@media screen and (min-width: 900px) {
  .intercom-messenger-frame {
    width: 552px !important;
    left: 50% !important;
    right: auto !important;
    transform: translateX(-50%) !important;
    top: 32px !important;
    bottom: 32px !important;
    height: calc(100% - 64px) !important;
    border-radius: 24px !important;
    max-height: none !important;
  }
}

.remove-intercom {
  width: 0 !important;
}

@media screen and (max-width: 899px) {
  .intercom-messenger-frame {
    width: 100% !important;
    max-height: 100% !important;
    height: 100% !important;
    left: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    border-radius: 0 !important;
  }
}

.my-custom-radio {
  width: 360px;
  border: 1.5px solid var(--grey-grey-50, #7b8794);
  height: 60px;
  border-radius: 8px;
  padding: 12px 50px 12px 50px;
  text-align: center;
  margin-left: 0 !important;
  background: #fff;
}
.my-custom-radio:has(.Mui-checked) {
  border: 1px solid var(--secondary-color-secondary-50-main, #705995);
  background: var(--secondary-color-secondary-50-main, #705995);
  color: #fff;
}
.my-custom-radio .MuiRadio-root {
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
}

.my-custom-radio2 {
  border: 1.5px solid var(--grey-grey-50, #7b8794);
  border-radius: 8px;
  padding: 12px 18px;
  text-align: center;
  justify-content: center;
  margin-left: 0 !important;
  background: #fff;
}
.my-custom-radio2:has(.Mui-checked) {
  border: 1px solid var(--secondary-color-secondary-50-main, #705995);
  background: var(--secondary-color-secondary-50-main, #705995);
  color: #fff;
}
.my-custom-radio2 .MuiRadio-root {
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
}

.clearButton {
  padding: 4px;
  border-radius: 50px;
  background-color: var(--secondary-color-secondary-10, #e9d9fc);
  width: 32px;
  height: 32px;
  cursor: pointer;
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
